.user_experince_form_container.link_checker_settings .form_column {
  /* display: flex; */
  flex-direction: column !important;
  align-items: center;
}

.user_experince_form_container.link_checker_settings {
  border: 1px solid #ff601f;
  padding: 20px;
}

.row-title-text,.row-content-text{
  font-size: 13px !important;
}

.userexp_input {
  width: 250px;
  padding: 0 0 0 17px;
  height: 37px;
  border: 1px solid #ff601f;
  border-radius: 4px;
}

@media(max-width:500px) {
  .userexp_input {
    width: 181px;
  }
}

.custom-reporttype-dropdown__control {
  width: auto !important;
  height: 52px !important;
}
.custom-report-dropdown__control {
  width: auto !important;
  height: auto !important;
}