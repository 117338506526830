.userdatas {
    text-align: center;
    float: left;
    width: 100%;
    /* padding-bottom: 3em; */
}

.userdatas h1 {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 36px; */
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 1;
}

.userdatas_inner p {
    /* font-size: 22px; */
    color: #fff;
}

.userdatas_inner {
    /* background: #fe874e 0% 0% no-repeat padding-box; */
    margin: 0 auto;
    text-align: center;
    max-width: 66%;
    border-radius: 17px;
    padding: 1em 0em 2em;
}

.google_analytics_form_outer {
    justify-self: center;
    margin: 10%;
}

.google_analytics_form {
    border-radius: 17px;
    border: 1px solid #ff6220;
    padding: 2em;
    background-color: #fff;
}

.google_analytics_btn {
    background: #ff6220;
    color: #fff;
    border: 1px solid #ff6220 !important;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}

.google_analytics_input {
    background: #e9eff6;
    color: #1a1818;
    padding: 5px;
    border: 1px solid #ff6220 !important;
}

.input[type="password"],
input[type="text"] {
    padding: 0 0 0 17px !important;
    border-radius: 4px;
    width: 250px;
    height: 37px;
}

@media(max-width:500px) {
    .input[type="password"],
    input[type="text"] {
        width: 181px;
    }
}

.domain-password .MuiInputBase-input {
    height: 37px;
}

.domain-password {
    border: 1px solid #ff601f;
    width: 269px;
}

.copy-url-container {
    display: flex;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.copybutton {
    border-radius: 0 10px 10px 0 !important;
    margin: 0 !important;
}

.copy-text-field {
    border: 1px solid #ff601f;
    border-radius: 10px 0px 0px 10px !important;
}