.cnt_hdr_popup_blw_wrap .run_rpt_btn.rn_rpt_btn {
    color: white;
    background: transparent linear-gradient(146deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    margin-right: 15px;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter:blur(11px);
    padding: 12px 20px;
}
.run_rpt_btn.rn_rpt_btn {
    color: white;
    background: #103658 !important;
    margin-right: 15px;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter:blur(11px);
    padding: 6px 13px;
}
.error_msg{
    color: red;
}
.formsubmit_popup{
    
        /* width: 441px; */
        margin: 0px auto;
        height: 300px;
        padding: 80px;
        /* text-align: center; */
}
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    text-align: center;
    /* overflow-wrap: anywhere !important; */
}
.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-row {
    align-items: center;
}
/* .over_all_container.search_prnt {
    overflow: visible !important;
} */
.css-z532jj-InputComponent {
    width: 0px !important;
}
input.react-dropdown-select-input.css-m6dlzq-InputComponent.e11wid6y0::placeholder,
.react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0::placeholder {
    color: #fff;
}
.search_key_wrap button.run_rpt_btn.rn_rpt_btn {
    background: #ff601f !important;
    margin: 0px;
    color: white !important;
}
.search_key_wrap button.run_rpt_btn.rn_rpt_btn:hover {
    /* background: #ffffff !important; */
    /* color: #000 !important; */
    /* box-shadow: 0px 0px 2px 2px #fe874e; */
}
.search_key_wrap.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button.run_rpt_btn.rn_rpt_btn:hover {
    background: #ff601f !important;
    color: #fff;
    box-shadow: none;
} 
.plagarism_analyzer_row .input_col input{
padding: 0px 15px;
}
.plagarism_analyzer_row.no_pad .input_col input{
    padding: 0px 0px !important;
    text-align: center;
    border: none;
    }
.search_key_wrap .plagarism_analyzer_row .input_col {
    width: 250px;
}
.search_key_wrap .plagarism_analyzer_row .button_col {
    width: unset;
}
.search_key_wrap .plagarism_analyzer_row {
    display: flex;
    gap: unset;
    padding: unset;
    margin: 16px 0px;
    justify-content: space-around;
}
.search_key_wrap .plagarism_analyzer_row .input_col input {
    /* font-size: 16px; */
    width: 250px;
    text-align: center;
    color: #676767;
}
.search_key_wrap .plagarism_analyzer_row .css-jznujr-ContentComponent{
    align-items: center;
    justify-content: center;
}
.search_key_wrap .css-wmy1p7-ReactDropdownSelect{
    background: white;
    padding: 0px;
    border: 1px solid black;
}
.search_key_wrap .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: #fe874e;
}
p#alert-dialog-slide-description .cnt_hdr_popup_blw_wrap {
    text-align: center;
}
p#alert-dialog-slide-description .react-dropdown-select-content span {
    color: #000000 !important;
}
.search_key_wrap .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root,
.MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount,
input.react-dropdown-select-input.css-1c8t16q-InputComponent.e11wid6y0::placeholder{
    color: #0b2033;
}
.search_key_wrap .plagarism_analyzer_row .input_col input::placeholder {
    color: #0b2033;
}
.search_key_wrap .plagarism_analyzer_row .input_domain_field::placeholder{
    color: #000 !important;
}
.search_key_wrap  input.react-dropdown-select-input.css-1bwklky-InputComponent.e11wid6y0 {
    display: none;
}
.search_key_wrap .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root .css-i4bv87-MuiSvgIcon-root {
    /* font-size: 1.1rem; */
}

.search_volume_table, svg {
margin-left: -4px;
}

.searchvolume-dropdown {
    width: 100%;
}

.search-volume-location {
    /* width: 100% !important; */
}

  /* Media query for screens smaller than 500px */
  @media (max-width: 500px) {
    .plagarism_analyzer_row {
      flex-direction: column; /* Stacks the fields in a column */
    }
  
    .input_col,
    .button_col {
      width: 100%; /* Makes each column take full width */
      margin-bottom: 1rem; /* Adds space between fields */
    }
    /* .button_col{
        margin-left: 20px;
    } */
  }
  