.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    padding: 25px 10px 25px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fe874e;
}
.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding-left: 10px;
}
.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button {
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-family: "Montserrat Alternates","Helvetica","Arial",sans-serif;
    color: #000000;
    font-weight: 700;
    /* font-size: 13px; */
    background: #fff !important;
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
   
}
.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn {
    /* padding: 7px; */
    /* margin-bottom: 20px; */
    background:#ff601f !important;
    font-weight: 700;
}

.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn:hover {
color: #000000 !important;
}
.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding: 3px;
    display: flex;
    gap: 30px;
    align-items: center;
}
.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 15px;
}
.cnt_audit_outer_wrap span._btn_icon_calender {
    border-left: 0px solid #ffffff40;
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td {
    text-align: center;
    font-weight: 500;
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap{
  padding: 10px;
    background: #e9e9e92e;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody {
    text-transform: capitalize;
    /* font-size: 14px; */
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody .wer_url_pg{
    text-transform: none;
}
.cnt_audit_outer_wrap{
color:#ffff;
}
.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
    width: 40px;
}
.cnt_audit_outer_wrap img.view_td_img {
    width: 35%;
    background: #e5e6e9 !important;
    padding: 5px 15px;
    border-radius: 7px;
}
.cnt_audit_outer_wrap span._btn_icon_calender img {
  width: 18px;
    margin: 5px 3px;
}
/* .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap {
    display: flex;
    justify-content: flex-end;
    gap:15px;
} */
.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2 {
    margin: 0px;
    text-align: left;
    letter-spacing: 0px;
    color: #0b2033;
    font-weight: 700;
    opacity: 1;
}
.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
    margin: 0px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #0b2033;
    /* font-size: 14px; */
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td:first-child{
    display: flex;
    justify-content: center;
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr .th_fl_report_wrap{
  width: 8%;
}
.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table {
    border-spacing: 0px 10px;
}
.cnt_hdr_blw_wrap {
    margin-left: 0px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    margin-top: 50px;
    margin-left: 30px;
}
.ReactModal__Content.ReactModal__Content--after-open {
    height: 250px;
    margin: auto;
}
.text-error{
    color:#ffa910;
}
/*table changes suriya*/
.MuiDataGrid-columnHeadersInner svg path,.MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable.css-gl260s-MuiDataGrid-columnHeadersInner,.MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone, .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,.MuiTablePagination-root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: #0b2033;
}
p#alert-dialog-slide-description{
/* color: #fff;; */
color: #103658;

}
.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-5wly58-MuiDataGrid-root {
    /* background: #472f5e; */
    border: 0px;
}
.MuiDataGrid-cell{
justify-content: center !important;
}
.cnt_audit_outer_wrap img.view_td_img { 
    width: 35% !important; 
    background: #e5e6e9 !important;
    padding: 5px 15px !important;
    border-radius: 7px !important;
}
h2#alert-dialog-title {
    text-align: center;
    /* color: white; */
    color: #103658;
}
.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
    justify-content: center;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper .cnt_hdr_blw_wrap.row:first-child .col{
    display:flex;
    color: white;
    gap:10px;
}
.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root button{
/* font-size: 14px; */
    color: white;
    text-transform: capitalize;
    background: #e9e9e92e !important;
    border: 0px;
    border-radius: 5px;
    padding:10px 10px;
}

    

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
    /* padding-bottom: 70px; */
}
.react-datepicker__close-icon::after{
    background-color: #f19b00 !important;
}
.css-5wly58-MuiDataGrid-root .MuiDataGrid-withBorderColor{
    /* border-color: rgb(224 224 224 / 0%) !important; */
}
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders,.MuiDataGrid-virtualScroller.css-axafay-MuiDataGrid-virtualScroller{
    background: #fff;
    /* height:unset; */
}
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
    margin-bottom: 0px;
  
}
.rankinglistdetail .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders{
    min-height: 95px !important;
}
.read_score_org_faile{
    background: #fa0404;
    /* margin: 41px 65px; */
    padding: 3px 36px;
    border-radius: 7px;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-family: "Montserrat Alternates","Helvetica","Arial",sans-serif;    
    font-weight: 400;
    /* font-size: 1rem; */
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.read_score_gry_success{
    background: #3cf503;
    /* margin: 41px 65px; */
    padding: 3px 36px;
    border-radius: 7px;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-family: "Montserrat Alternates","Helvetica","Arial",sans-serif; 
    font-weight: 400;
    /* font-size: 1rem; */
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color:black;
}
button.buttonView {
    background: #fff;
    border-radius: 8px;
    width: 150px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.plagarism_checker_table button.buttonView {
    margin-left: 80px;
    margin-right: 20px;
}
img.view_std_img {
    background: #e5e6e9;
    width: 24% !important;
    margin-left: 8px;
    padding: 4px !important;
    border-radius: 10px;
}
.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap_detail img {
    width: 13%;
}
.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap_detail {
    display: flex;
    justify-content: flex-end;
}
.cnt_hdr_content{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.cnt_hdr_content_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.schema_missing_waring_wrap {
    width: 165px;
    padding: 0 10px;
    background: #5a474742 !important;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}
.schema_missing_waring_wrap h4 {
    margin: 0;
}
.cnt_hdr_content {
    padding-bottom: 30px;
}

.cnt_hdr_content_table {
   
    background: #f0f8ff14 !important;
    padding: 30px 0px;
    /* margin-top:25px; */
}


.cnt_hdr_content_box_outer {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;
}
.cnt_hdr_content_table table.table tr {
    vertical-align: initial;
}
.cnt_hdr_content_table table.table tr td {
    padding-bottom: 12px;
}
.cnt_hdr_content_table table.table tr td:first-child {
    min-width: 155px;
}
.cnt_audit_outer_wrap::-webkit-scrollbar {
    display: none;
}
/* .hor_line h3:after {
    width: 60px;
    content: ' ';
    display: block;
    border: 1px solid white;
} */
.cnt_audit_outer_wrap.ContentAuditWrap .table tr td:first-child {
    text-align: center;
}
.cnt_audit_outer_wrap.ContentAuditWrap .cnt_hdr_content_table h3 {
    border-bottom: 2px solid #0b2033;
    width: fit-content;
    width: -moz-fit-content;
}
.cnt_hdr_content_table table.table tr td:second-child {
    min-width: 55px;
}
td img {
    width: 40px;
    margin-left: 40px;
    vertical-align: top;
}
.css-1ash2gx-DropDown,.css-dpcmdr-DropDown {
    background:#976e6e !important;
}
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor {
    color: #0b2033;
    /* font-size: 16px; */
    outline: none !important;
}
.ContentAuditWrap div {
    padding-left: 10px;
}
/* Web view */

@media (min-width: 1200px){
    .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2{
        font-weight: 700;
    
    } 
    .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
        margin: 0px !important;
        text-align: left;
        letter-spacing: 0px;
        color: #0b2033;
        /* font-size: 14px; */
    }


}

/* Tablet view */

@media (max-width: 780px){
    .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2{
        /* font-size: 18px; */
        
    } 
    
    .cnt_audit_outer_wrap .cnt_hdr_blw_wrap button {    
        /* font-size: 11px;        */
    }
    .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
        width: 30px;
        height: 30px;
    }
    .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
        margin: 0px !important;
        text-align: left;
        letter-spacing: 0px;
        color: #0b2033;
        /* font-size: 12px; */
    }
    .cnt_audit_outer_wrap .cnt_hdr_top_wrap {
        padding: 60px 0px 60px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fe874e;
    }
    p#alert-dialog-slide-description{
        /* font-size: 12px; */
    }
}

/* mobile view */
@media (max-width:500px){
    .col input[type="radio"] {
        margin-right: 5px; /* Space between radio buttons */
      }
      .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2{
        /* font-size: 16px; */
        
    }
.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
    width: 25px;
    /* height: 20px; */
}
/* .faq_class{
    
        padding:1px;
         width: 20px; 
        height:35px;
   
} */
.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
    margin: 0px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #0b2033;
    /* font-size: 12px; */
    /* line-height: 25px; */
}

.external_link_data_row .cols .content_col {
    padding: 6px;
    font-weight: 400;
    /* font-size: 10px; */
}
}
/* new edit */
.cnt_hdr_top_lft_wrap {
    line-height: 1.5;
    width: 70%!important;
    overflow-wrap: break-word!important;
  }
  @media (max-width: 500px) {
    .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
        margin-left: 10px;
    }
    .cnt_audit_outer_wrap span._btn_icon_calender img {
        width: 18px;
        margin: 5px 3px;
        margin-left: 30px;
    }
  }
  @media (max-width: 768px){
    .cnt_audit_outer_wrap span._btn_icon_calender img {
        width: 18px;
        margin: 5px 3px;
        margin-left: 30px;
    }
  }