.tab_otr_outer img.login_tab_img {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000073;
    border-radius: 7px;
    display: block;
    width: 35%;
    padding: 14px;
    margin: 0 auto;
}

.tab_otr_outer div#lgn-tab-container {
    display: flex;
    align-items: center;
}

.tab_otr_outer .das_tab_container img {
    transition: 1.0s ease-in-out;
}

.tab_otr_outer .tab_head_container_right {
    flex-basis: 50%;
}

.tab_otr_outer .tab_head_container_right img.login_lft_img {
    width: 100%;
    object-fit: contain;
}

.login_tab_img_outer {
    margin: 30px 0px;
}

.tab_otr_outer .css-pwajt6 {
    background: unset;
}

.tab_otr_outer .tab_head_container_lft {
    margin: 40px 0px;
    flex-basis: 50%;
    background: #fe874e 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 20px;
}

@media (max-width:425px) {
    .tab_otr_outer .tab_head_container_lft {
        margin: -30px 0px;
        flex-basis: 50%;
        background: #fe874e 0% 0% no-repeat padding-box;
        border-radius: 20px 20px 20px 20px;
    }
}


.tab_otr_outer {
    display: flex;

    gap: 42px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.MuiTabs-flexContainer {
    justify-content: center;
}

.tab_otr_outer .css-pwajt6 {
    margin: 0 auto;
    max-width: 80% !important;
}

.tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    background: #103658 !important;
    transition: background-color 0.3s ease-in-out;
    color: #fff;
}

.tab_otr_outer .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-height: 38px;
    padding: 9px 28px;
}

.tab_otr_outer .frm_otr_wrap a.forgot-password:hover {
    text-decoration: underline;
    color: #0B2033;
}

.tab_otr_outer .tab_link_outr_wrap a:hover {
    color: #fff;
    background: #ff601f;
    transition: background-color 0.3s ease-in-out;
}

.tab_otr_outer button.sbt_btn:hover {
    cursor: pointer;
}

.tab_otr_outer h6.tab_header {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 36px; */
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 1;
}

.tab_otr_outer .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
    display: none;
}

.tab_otr_outer .tab_link_outr_wrap {
    display: flex;
    /* width: 95%; */
    gap: 10px;
    justify-content: space-between;
}

.tab_otr_outer .MuiTabs-flexContainer button {
    color: #0b2033;
    margin: 0px;
    text-transform: math-auto;
    background: unset;
    border-radius: 6px;
    /* font-size: 20px; */
    /* padding: 10px 68px; */
    min-height: 38px;
    padding: 9px 28px;
}

.tab_otr_outer .MuiTabs-flexContainer button:hover {
    color: #fff;
    background-color: #103658;
    /* Change this to your desired hover background color */
}


.tab_otr_outer .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX.css-69z67c-MuiTabs-scroller {
    text-align: center;
}

.tab_otr_outer .css-heg063-MuiTabs-flexContainer {
    margin: 0 auto;
    background: #ffffffde 0% 0% no-repeat padding-box;
    display: inline-block;
    border-radius: 6px;
}

.tab_otr_outer .frm_otr_wrap .form-group.email_grp {
    margin: 35px 0px;
}

.tab_otr_outer .frm_otr_wrap input#email_Phone {
    /* border-radius: 5px;
    padding: 28px 10px 28px 10px;
    border: 2px solid #ff601f;
    background: #ffffff  0% 0% no-repeat padding-box; */
    /* font-size: 17px; */
    /* width:-webkit-fill-available; */
    width: 94% !important;
}

.tab_otr_outer .frm_otr_wrap input#password {
    border-radius: 4px;
    margin: 0px 0px;
    padding: 28px 10px 28px 10px;
    border: 0px;
    /* max-width: 90%; */
    width: 94% !important;
    height: 37px;
    border: 1px solid #ff601f;

    /* width: -webkit-fill-available; */
    background: #ffffff  0% 0% no-repeat padding-box;
    /* font-size: 17px; */
}

.tab_otr_outer .frm_otr_wrap input#password::placeholder,
.tab_otr_outer .frm_otr_wrap input#email_Phone::placeholder {
    color: #000;

}

/* Style the input field when it has focus */
.tab_otr_outer input[type="text"]:focus,
.tab_otr_outer input[type="password"]:focus {
    outline: none;
    border-color: #ff601f;
    box-shadow: 0 0 5px rgba(194, 196, 194, 0.8);
}

/* Style the input field placeholder text */
.tab_otr_outer input[type="text"]::placeholder,
.tab_otr_outer input[type="password"]::placeholder {
    color: #000000;
    text-align: initial;
}

/* Style the input field placeholder text when the input has focus */

.tab_otr_outer p.lgn_trms a:hover {
    text-decoration: underline;
}

.tab_otr_outer button.sbt_btn:hover {
    color: #232a36;
    background: #ffffffe6;
}

.tab_otr_outer .frm_otr_wrap a.forgot-password,
.tab_otr_outer .frm_otr_wrap span.forgot-password {
    color: #ffffff;
    text-decoration: none;
    /* font-size: 13px; */
    display: block;
    /* text-align: end; */
    padding: 15px 0px;
    margin-right: 16px;
}

.tab_otr_outer .frm_otr_wrap .remember_me {
    cursor: pointer;
    position: relative;
    top: 2px;
}

.tab_otr_outer .tab_link_outr_wrap a {
    border: 1px solid #ff601f;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 11px;
    text-decoration: none;
    color: #0b2033;
    padding: 10px 10px;
    /* font-size: 14px; */
    align-items: center;
    margin-bottom: 30px;
}

.tab_otr_outer .tab_link_outr_wrap a span img {
    max-width: 18px;
    max-height: 18px;
    padding-left: 10px;
}

.tab_otr_outer button.sbt_btn {
    /* font-size: 18px; */
    background: #103658 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 20px 0px;
    /* max-width: 95%; */
    border-radius: 5px;
    width: 100%;

}

/* .tab_otr_outer button.sbt_btn.crt_act{
    margin-bottom: 0px;   
} */
.tab_otr_outer .frm_inner_wrap {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.tab_otr_outer .frm_otr_wrap {
    margin-bottom: 40px;
}

.tab_otr_outer p.lgn_trms {
    color: #fff;
    text-align: center;
    /* font-size: 11px; */
}

.tab_otr_outer p.lgn_trms a {
    text-decoration: none;
    color: #0B2033;
}

/* .tab_otr_outer .frm_otr_wrap input#password.sgn_pswd {
    margin-bottom: 25px;
} */
.tab_otr_outer .frm_otr_wrap .form-group.pass_field_grp {
    margin-bottom: 25px;
}

/* .tab_otr_outer .tab_head_container_right img {
    width: 500px;
} */
.tab_otr_outer .tab_head_container_right img.RegisterImage {
    height: 940px;
    width: 500px;
    object-fit: cover;
}

.tab_otr_outer .frm_otr_wrap .frm_error {
    /* font-size: 16px; */
    /* color: #0B2033; */
    color: #ff0000;
}

.tab_otr_outer .frm_otr_wrap .register_term_accept {
    padding-bottom: 25px;
}

.tab_otr_outer .frm_otr_wrap .form-group.register_term_accept span a:hover {
    text-decoration: none;
}

.tab_otr_outer .frm_otr_wrap .form-group.register_term_accept span a {
    color: #0B2033;
}

.tab_otr_outer .toggle-password {
    top: 28px;
    cursor: pointer;
    right: 40px;
    position: absolute;
    z-index: 999;
}

/* .tab_otr_outer form.frm_container {
    width: 100%;
} */
.tab_otr_outer .frm_otr_wrap .form-group {
    position: relative;
}

svg.FaEyeSlash,
svg.FaEy {
    /* font-size: 17px; */
}

/*password reset links*/
.reset_page_container.tab_otr_outer .tab_head_container_lft {
    /* margin: 40px 0px; */
    flex-basis: 50%;
    background: unset;
    border-radius: 20px;
    background: #fff;
    padding: 40px;
}

.reset_page_container.tab_otr_outer {
    align-items: center;
    gap: 30px;
    /* padding: 35px; */
    border-radius: 15px;
    margin: 55px;
    /* background: #ffffff1c 0% 0% no-repeat padding-box; */
}

.reset_page_container.tab_otr_outer img.login_tab_img {
    width: 55%;
    /* margin: unset; */
}

.reset_page_container.tab_otr_outer .tab_head_innr_container {
    margin: auto;
    max-width: 100%;
}

.reset_page_container.tab_otr_outer h1.tab_header {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 30px; */
    letter-spacing: 1px;
    color: #ff601f;
    opacity: 1;
}

.reset_page_container.tab_otr_outer .frm_otr_wrap .form-group.email_grp {
    margin-bottom: 35px;
    margin-top: 0px;
}

.reset_page_container.tab_otr_outer a.lgn_rdrct_link {
    /* font-size: 23px; */
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 13px 10px;
    color: #000;
    text-decoration: none;
    border: 2px dashed #ff601f;
}

.reset_page_container.tab_otr_outer .frm_otr_wrap input#email_Phone {
    padding: 15px 10px;
    width: -webkit-fill-available;
}

.reset_page_container.tab_otr_outer button.sbt_btn {
    padding: 18px 10px;
    margin-bottom: 35px;
}

.reset_page_container.tab_otr_outer .frm_otr_wrap .frm_error {
    /* color: #0B2033; */
    color: #ff0000;
}

/*reset success msg*/
.rest_scs_outer_container.confirm_sec_outer_container .rest_scs_ins_container img.Eso_logo {
    display: block;
    margin: 0 auto;
    width: 82%;
    background: #ffff;
    border-radius: 10px;
    padding: 10px;
}

.rest_scs_outer_container .rest_scs_ins_container img.Eso_logo {
    display: block;
    margin: 0 auto;
    width: 22%;
}

.rest_scs_outer_container .rest_scs_ins_container img.Eso_rst_img {
    margin: 0 auto;
    display: block;
    width: 232px;
    object-fit: contain;
}

.rest_scs_outer_container .rest_scs_ins_container {
    border-radius: 15px;
    padding: 20px;
    margin: 40px 30px;
    background: #ff601f 0% 0% no-repeat padding-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.rest_scs_outer_container h1.scs_tt {
    margin-top: 0px;
    /* font-size: 42px; */
    text-align: center;
    color: #fff;
}

.rest_scs_outer_container .rest_inst_wrap {
    padding: 70px 0px;
    border-radius: 17px;
    /* font-size: 14px; */
    font-weight: 400;
    color: #0b2033;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin: 0 auto;
    text-align: center;
}

.rest_scs_outer_container .rest_inst_wrap a {
    color: #fff;
}

.rest_scs_outer_container .rest_inst_wrap a:hover {
    text-decoration: none;
}

.reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
    vertical-align: super;
    object-fit: cover;
    width: 570px;
    height: 480px;
    background: unset;
}

/*otp conformation*/
.rest_scs_outer_container.confirm_sec_outer_container .rest_inst_wrap {
    padding: 1px 10px 0px;
    width: 91%;
}

.rest_scs_outer_container.confirm_sec_outer_container .frm_error {
    /* color: #0B2033; */
    color: #ff0000;
    ;
}

.rest_scs_outer_container.confirm_sec_outer_container input#otppas {
    /* font-size: 17px; */
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #ff601f;
    /* width: 250px !important; */
    padding: 17px 22px;
    height: 37px;
}


.rest_scs_outer_container.confirm_sec_outer_container input#otppas::placeholder {
    color: #444040;
}

.rest_scs_outer_container.confirm_sec_outer_container p.otp_tymval {
    margin-bottom: 0px;
    margin-top: 10px;
}

.rest_scs_outer_container.confirm_sec_outer_container input#otppas:focus {
    outline: none;
    border-color: rgb(194 196 194 / 0%);
    box-shadow: 0 0 5px rgb(194 196 194);
}

.rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn {
    border-radius: 10px;
    /* font-size: 20px; */
    border: 1px solid #fff !important;
    top: 25px;
    position: relative;
    color: #fff;
    width: 40%;
    padding: 20px 20px;
    background: #ff601f;
}

.rest_scs_outer_container.confirm_sec_outer_container .cnfrm p {
    /* font-size: 14px; */
    text-align: center;
    color: #fff;
}

.rest_scs_outer_container.confirm_sec_outer_container .cnfrm {
    margin: auto;
    /* max-width: 60%; */
    margin-top: 44px;
}

.rest_scs_outer_container.confirm_sec_outer_container .cnfrm p a {
    color: #fff;
    text-decoration: underline;

}

.rest_scs_outer_container.confirm_sec_outer_container .cnfrm p a:hover {
    text-decoration: none;
}

.confirm_sec_outer_container .Eso_cnfrm_mail_outer_wrap {
    position: relative;
    min-height: 250px;
    max-width: 263px;
    margin: 0 auto;
}

.confirm_sec_outer_container .rest_scs_ins_container img.Eso_rst_img {
    transform: translateX(-25px);
    bottom: -11px;
    position: absolute;
    display: block;
    width: 308px;
    object-fit: contain;
    background: transparent;

}

.confirm_sec_outer_container .rest_scs_ins_container img.Eso_rst_img.OtpLockNaimationGif {
    transform: translateX(-44px);
    bottom: 0px;
    left: 80px;
    display: block;
    width: 160px;
    object-fit: contain;
    background: transparent;

}

.profile_update_button:hover,
.rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn:hover {
    background: #ffffff;
    color: #0b2033;
    box-shadow: 0px 0px 2px 2px #ff601f;
}

.forgot_remember_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (min-width: 600px) and (max-width:768px) {
    .tab_otr_outer {
        flex-direction: column;
    }

    .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
        width: 200px !important;
    }

    .tab_otr_outer .tab_link_outr_wrap {
        gap: 20px;
    }

    .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
        padding: 0px !important;
    }

    .reset_page_container.tab_otr_outer button.sbt_btn {
        padding: 15px 0px;
        margin-bottom: 35px;
        /* font-size: 14px; */
    }

    .reset_page_container.tab_otr_outer .frm_otr_wrap input#email_Phone {
        padding: 15px 10px 15px 30px;
        /* font-size: 14px; */
    }

    .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
        vertical-align: super;
        object-fit: cover;
        width: 416px;
        height: 350px;
    }

    /* .reset_page_container.tab_otr_outer a.lgn_rdrct_link{
        width: 180px;
        font-size: 13px;

    } */
    /* .reset_page_container.tab_otr_outer h1.tab_header{
        font-size: 20px;
    } */
    .tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        width: 20px;
    }

    .tab_otr_outer .tab_head_container_right img.RegisterImage {
        height: 500px;
        width: 350px;
        margin-top: 21px;
        object-fit: cover;
    }

    .tab_otr_outer img.login_tab_img {
        width: 25%;
    }

    .tab_otr_outer .tab_link_outr_wrap a {
        padding: 5px 10px;
        /* font-size: 10px; */
    }

    /* .tab_otr_outer .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX.css-69z67c-MuiTabs-scroller {
        text-align: start;
    } */
    .tab_otr_outer .frm_otr_wrap input#email_Phone {
        padding: 10px;
        /* font-size: 12px; */
        /* width:80% ; */
    }

    .forgot_remember_row {
        display: block;
    }

    .tab_otr_outer button.sbt_btn {
        padding: 5px;
    }

    .css-pwajt6 .frm_container input[type="text"] {
        height: 37px;
        padding: 0 0 0 17px !important;
    }

    .tab_otr_outer .toggle-password {
        top: 8px;
    }

    .tab_otr_outer .frm_otr_wrap input#password {
        padding: 10px;
        /* font-size: 12px; */
    }

    /* .tab_otr_outer .frm_otr_wrap a.forgot-password ,.tab_otr_outer .frm_otr_wrap span.forgot-password{
        font-size: 11px;
    } */
    .tab_otr_outer .frm_otr_wrap a.forgot-password {
        margin-top: 3px;
    }

    /* .tab_otr_outer h6.tab_header {
        font-size: 22px;
    } */

}

@media(max-width: 500px) {
    .tab_otr_outer .toggle-password {
        top: 18px;
        right: -12px;
    }

    /* .reset_page_container.tab_otr_outer h1.tab_header{
        font-size: 25px;
    } */
    /* .tab_otr_outer button.sbt_btn {
        font-size: 12px;
    } */
    .reset_page_container.tab_otr_outer a.lgn_rdrct_link {
        /* font-size: 12px; */
        text-align: center;
        display: block;
        border-radius: 4px;
        padding: 15px;
        color: #000000;
        text-decoration: none;
        border: 2px dashed #ff601f;
    }

    .tab_otr_outer .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX.css-69z67c-MuiTabs-scroller {
        text-align: start;
    }

    .tab_otr_outer .frm_otr_wrap .form-group {
        position: relative;
        /* font-size: 11px; */
    }

    .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
        padding: 0px;
    }

    /* .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button{
        font-size: 12px;
    } */
    .tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        width: 10%;
        height: 40px;
        /* font-size: 10px; */
    }

    .tab_otr_outer .tab_link_outr_wrap {
        gap: 10px;
    }

    .tab_otr_outer .frm_otr_wrap input#password {
        padding: 18px 10px 18px 10px;
        /* font-size: 15px; */
    }

    .reset_page_container.tab_otr_outer {
        align-items: flex-end;
        gap: 0px;
        /* padding: 0px; */
        border-radius: 15px;
        /* margin: 65px; */
        background: #ffffff1c 0% 0% no-repeat padding-box;
    }

    .tab_otr_outer {
        display: block;
    }

    .tab_otr_outer button.sbt_btn {
        margin-bottom: 20px;
        padding: 15px 0px;
    }
}

@media(max-width: 400px) {

    /* .tab_otr_outer .tab_head_container_lft {
        width: 320px;
    } */
    .tab_otr_outer .frm_otr_wrap a.forgot-password {
        margin-right: 10px;
    }

    /* .forgot_remember_row{
        display: block;
    } */
    .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
        width: 230px;
    }

    .reset_page_container.tab_otr_outer {
        align-items: flex-end;
        gap: 0px;
        /* padding: 0px; */
        border-radius: 15px;
        background: #ffffff1c 0% 0% no-repeat padding-box;
    }
}

@media(max-width: 362px) {
    .forgot_remember_row {
        display: block;
    }
}

@media(max-width: 320px) {

    /* .tab_otr_outer .tab_head_container_lft {
            width: 270px;
        } */


    .tab_otr_outer .frm_otr_wrap a.forgot-password {
        margin-right: 10px;
    }

    /* .tab_otr_outer .frm_otr_wrap input#email_Phone{
        font-size: 12px;
    } */
    .tab_otr_outer .frm_otr_wrap input#password {
        padding: 18px 10px 18px 10px;
        /* font-size: 11px; */
    }

    .reset_page_container.tab_otr_outer {
        align-items: flex-end;
        gap: 0px;
        /* padding: 0px; */
        border-radius: 15px;
        margin: 35px;
        background: #ffffff1c 0% 0% no-repeat padding-box;
    }
}

@media(max-width:500px) and (min-width:380px) {
    .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
        width: 400px !important;
        height: 300px !important;
        /* margin-left: 118px; */
    }
}

@media(max-width:300px) and (min-width:250px) {
    .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
        width: 120px !important;
        height: 200px !important;
        /* margin-left: 118px; */
    }
}

.das_tab_container {
    margin: auto;
}

@media(min-width:250px) and (max-width:584px) {
    .profile_update_button {
        display: grid;
        margin: auto;

    }

    .rest_scs_outer_container.confirm_sec_outer_container input#otppas {
        margin-bottom: 20px;
        font-size: 9px;
    }
}

@media(max-width:425px) and (min-width:250px) {
    .profile_update_label {
        display: grid !important;
    }

    .subscription_wrap .plans_list .container {
        display: block;
    }

    .subscription_wrap .plans_list .container>div {
        width: 90% !important;
        margin-bottom: 20px;
    }
}


.tab-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MuiTabs-flexContainer {
    background-color: #ffefe8;
    border-radius: 6px;
    border: 1px solid #c1bcbc;

}



.MuiTabs-indicator.css-ttwr4n {
    position: absolute;
    height: 0px;
    bottom: 0px;
    width: 100%;
    background-color: rgb(210, 62, 25);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*new style*/
.tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    background: #ff601f !important;
    color: #fff;
    transition: background-color 0.3s ease-in-out;
    color: #fff;
    /* padding: 15px 50px; */
}

.lgn_container .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button:hover {
    background-color: #ff601f !important;
    color: #fff;
    /* padding: 15px 50px; */
}

.MuiTabs-flexContainer {
    border: 1px solid #c1bcbc;
    border-radius: 6px;
}

.forgot-password {
    color: #000 !important;
}

.tab_otr_outer button.sbt_btn {
    background-color: #ff601f;
    width: 100% !important;
    padding: 10px 0px;
}

.tab_otr_outer button.sbt_btn:hover {
    color: #ffffff;
    background: #ff601f;
}

.tab_otr_outer .frm_otr_wrap .frm_error {
    font-size: 12px;
    word-spacing: 1.3px;
}

.tab_otr_outer .toggle-password {
    top: 10px;
    cursor: pointer;
    right: 40px;
    position: absolute;
    z-index: 999;
    background: #ffffff00 !important;
}

.tab_otr_outer.lgn_container.new_tab_otr_outer {
    margin-top: 50px;
    width: 75%;
}

.register_term_accept {
    color: #282525;
    font-size: 10px;
    line-height: 1.7;
    word-spacing: 1.3px;
}

.tab_otr_outer .frm_otr_wrap .form-group.register_term_accept span a {
    color: #30587c;
}

.tab_otr_outer .frm_otr_wrap .form-group.email_grp {
    margin: 20px 0px;
}

svg.FaEyeSlash,
svg.FaEy {
    color: #ff601f;
}

.tab_otr_outer .frm_otr_wrap input.newformdata::placeholder {
    color: #888888 !important;
    opacity: 1 !important;
    font-size: 12px;
    letter-spacing: 1px;
    padding-left: 15px;
}

.forgot-password {
    color: #000000 !important;
    font-size: 13px !important;
}

.remember_me:hover {
    border: solid #ff601f !important;
    /* Change border color on hover */
}

.remember_me:checked {
    border: solid #ff601f !important;
    /* Change border color when checked */
}

.remember_me:checked::after {
    border: solid #ff601f !important;
}

/* .MuiTabs-flexContainer{
    padding: 10px !important;
} */
.tab_otr_outer .MuiTabs-flexContainer button {
    color: #0b2033;
    margin: 0px;
    text-transform: math-auto;
    background: unset;
    border-radius: 6px;
    min-block-size: auto;
    /* font-size: 20px; */
    /* padding: 10px 68px; */
    /* padding: 15px 50px; */
}

.tab_otr_outer .MuiTabs-flexContainer button:hover {
    color: #fff;
    margin: 0px;
    text-transform: math-auto;
    background: #ff601f;
    border-radius: 6px;
    /* font-size: 20px; */
    /* padding: 10px 68px; */
    /* padding: 15px 50px; */
}

span.MuiTabs-indicator {
    display: none;
}


.MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX {
    margin-right: 30px;
    text-align: center;
}

.tab_otr_outer .tab_link_outr_wrap {
    color: #fff;
    /* background: #ff601f; */
    transition: background-color 0.3s ease-in-out;
}

img.login_rft_img:hover {
    background: unset !important;
}

.tab_link_outr_wrap_active {
    background-color: #ff601f !important;
    color: #fff !important;
}

img.login_lft_img {
    width: 80% !important;
    object-fit: contain !important;
    margin-top: 145px;
    margin-left: 40px;
}


@media(max-width:320px) {
    .MuiTabs-root.signallbutton {
        display: ruby;
        margin-left: -12px;
    }
}


@media(max-width:450px) {
    .tab_otr_outer img.login_tab_img {
        border-radius: 7px !important;
        display: block !important;
        width: 70% !important;
    }

    .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX {
        /* width: 100px !important; */
        display: block !important;
    }

    .tab_otr_outer h6.tab_header {
        font-size: 23px;
    }

    .tab_otr_outer .frm_otr_wrap input.newformdata {
        border-radius: 5px !important;
        padding: 12px 10px 12px 10px !important;
        border: 0px !important;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        font-size: 12px !important;
        border: 1px solid #ff601f !important;
        /* color: #ff0000; */
    }

    .forgot_remember_row {
        display: flex;
        width: 100%;
        align-content: center;
        align-items: baseline;
    }

    .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX {
        margin-right: 0px;
    }

    .tab_otr_outer .tab_link_outr_wrap {
        margin-left: 25px;
        margin-bottom: 0px;
    }

    .tab_otr_outer button.sbt_btn {
        width: 100% !important;
        margin-bottom: 0px;
    }

    .tab_otr_outer .tab_head_container_right img.login_rft_img {
        margin-top: 10px;
        margin-left: 20px;
    }

    img.login_lft_img {
        margin-top: 10px;
        margin-left: 20px;
    }

}

@media (max-width: 600px) {
    .tab_otr_outer .tab_link_outr_wrap {
        margin-left: 0px !important;
    }

    .tab_otr_outer.lgn_container {
        display: block;
    }

    img.login_lft_img {
        width: 66% !important;
        object-fit: contain !important;
        /* margin-top: 145px; */
        margin-left: 40px;
    }

    .tab_otr_outer .tab_link_outr_wrap {
        /* margin-left: 60px !important; */
        width: 100%;
    }
}

@media (max-width: 767px) {
    .tab_otr_outer img.login_tab_img.dataimg {
        padding: 20px 40px 4px 15px !important;
    }

    .tab_otr_outer.lgn_container.new_tab_otr_outer {
        margin-top: 50px;
        width: 100%;
    }

    .tab_otr_outer .css-heg063-MuiTabs-flexContainer {
        width: 180px;
    }

    .tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        width: 20px;
        padding: 11px !important;
    }

    .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
        padding: 11px !important;
    }

    .forgot_remember_row {
        display: flex;
    }

}

@media (max-width: 500px) {
    .tab_otr_outer .tab_link_outr_wrap a {
        padding: 6px !important;
    }

    .tab_otr_outer .frm_otr_wrap input#email_Phone {
        /* padding: 12px 3px 12px 6px !important; */
    }

    .tab_otr_outer .frm_otr_wrap input#password {
        padding: 0px 0px 0px 18px !important;
    }

    .tab_otr_outer .frm_otr_wrap a.forgot-password,
    .tab_otr_outer .frm_otr_wrap span.forgot-password {
        padding: 5px 0px;
        margin-right: 60px;
    }

    h2.tab_header.newdata {
        font-size: 17px;
    }

    .login_tab_img_outer {
        padding: 13px;
    }

    .tab_otr_outer .css-heg063-MuiTabs-flexContainer {
        width: 200px !important;
    }

    .tab-header {
        width: 208px;
    }

    .tab_otr_outer button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        width: 50%;
    }

    .tab_otr_outer .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        height: 40px;
    }
}



.rest_scs_outer_container .rest_inst_wrap1 {
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 17px;
    color: #0b2033;
    font-weight: 400;
    margin: 0 auto;
    padding: 70px 0;
    /* text-align: center; */
}


.rest_scs_outer_container.confirm_sec_outer_container .rest_inst_wrap1 {
    padding: 1px 10px 0;
    width: 91%;
}