.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    /* display: block; */
    word-wrap: break-word;
}

.access-score-dropdown__control {
    width: max-content !important;
}

.common-domain-dropdown__control {
    width: max-content !important;
    max-width: 269px;
}

@media (max-width: 500px) {
    .common-domain-dropdown__control {
        margin-top: 10px;
    }
}