.userdatas {
    text-align: center;
    float: left;
    width: 100%;
    padding-bottom: 3em;
}
.userdatas h1 {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 36px; */
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 1;
}
.userdatas_inner p {
    /* font-size: 22px; */
    /* color: #fff; */
    color: #103658;
}
.userdatas_inner {
    margin: 5% auto;
    text-align: center;
    max-width: 66%;
    border-radius: 17px;
    padding: 1em 0em 2em;
    border: 2px solid #ff601f;
    background-color: white;
}


.websitename label {
    margin-right: 25px;
    font-weight: 700;
}

@media (max-width: 500px) {
    /* .userdatas_inner p {
        font-size: 16px;
    } */
    .websitename label {
        /* width: 19%; */
        /* font-size: 12px; */
        text-align: center;
      
    }
    label.webtype {
        width: 30%;
        display: inline-block;
        /* font-size: 12px; */
       font-weight: 700;
    }
    .user_country input {
        /* font-size: 12px; */
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid #ff601f;
        width: 39%;
        padding: 17px 22px;
        color: #1a1818;
    }
    .form_next button {
        border-radius: 10px;
        position: relative;
        color: #fff;
        /* width: 70%; */
        padding: 10px 10px;
        margin-bottom: 20px;
    }
    .userdata .form_next button{
        /* width:50%; */
    }
    label.websiteauthentype {
        width: 40%;
        /* font-size: 12px; */
        font-weight: 700;
    }
}
